const Performance = () => (
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22"
      cy="18"
      r="16"
      fill="white"
      stroke="#05E200"
      strokeWidth="4"
    />
    <circle
      cx="18"
      cy="18"
      r="16"
      fill="white"
      stroke="url(#paint0_linear_60_537)"
      strokeWidth="4"
    />
    <path
      d="M12.367 20.248H14.179V22H8.34703V20.248H10.159V15.94L8.73103 16.756L7.93903 15.172L10.807 13.54H12.367V20.248ZM18.0023 22.108C16.9543 22.108 16.1543 21.736 15.6023 20.992C15.0503 20.248 14.7743 19.172 14.7743 17.764C14.7743 16.38 15.0503 15.316 15.6023 14.572C16.1623 13.828 16.9623 13.456 18.0023 13.456C19.0423 13.456 19.8383 13.828 20.3903 14.572C20.9503 15.316 21.2303 16.38 21.2303 17.764C21.2303 19.172 20.9543 20.248 20.4023 20.992C19.8503 21.736 19.0503 22.108 18.0023 22.108ZM18.0023 20.44C18.3863 20.44 18.6623 20.232 18.8303 19.816C18.9983 19.4 19.0823 18.716 19.0823 17.764C19.0823 16.828 18.9983 16.156 18.8303 15.748C18.6623 15.332 18.3863 15.124 18.0023 15.124C17.6183 15.124 17.3423 15.332 17.1743 15.748C17.0063 16.156 16.9223 16.828 16.9223 17.764C16.9223 18.716 17.0063 19.4 17.1743 19.816C17.3423 20.232 17.6183 20.44 18.0023 20.44ZM25.1977 22.108C24.1497 22.108 23.3497 21.736 22.7977 20.992C22.2457 20.248 21.9697 19.172 21.9697 17.764C21.9697 16.38 22.2457 15.316 22.7977 14.572C23.3577 13.828 24.1577 13.456 25.1977 13.456C26.2377 13.456 27.0337 13.828 27.5857 14.572C28.1457 15.316 28.4257 16.38 28.4257 17.764C28.4257 19.172 28.1497 20.248 27.5977 20.992C27.0457 21.736 26.2457 22.108 25.1977 22.108ZM25.1977 20.44C25.5817 20.44 25.8577 20.232 26.0257 19.816C26.1937 19.4 26.2777 18.716 26.2777 17.764C26.2777 16.828 26.1937 16.156 26.0257 15.748C25.8577 15.332 25.5817 15.124 25.1977 15.124C24.8137 15.124 24.5377 15.332 24.3697 15.748C24.2017 16.156 24.1177 16.828 24.1177 17.764C24.1177 18.716 24.2017 19.4 24.3697 19.816C24.5377 20.232 24.8137 20.44 25.1977 20.44Z"
      fill="url(#paint1_linear_60_537)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_60_537"
        x1="18"
        y1="0"
        x2="18"
        y2="36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C2FF" />
        <stop offset="1" stopColor="#016AC9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_60_537"
        x1="18"
        y1="10"
        x2="18"
        y2="26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01AAF1" />
        <stop offset="1" stopColor="#0183D9" />
      </linearGradient>
    </defs>
  </svg>
);

export default Performance;
