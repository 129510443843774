import Head from "next/head";
import {ReactNode} from "react";
import {createGlobalStyle, ThemeProvider} from "styled-components";

import theme from "../theme";

interface SystemProps {
  children: ReactNode;
  title?: string;
  content?: string;
}

export default function System({children, content, title}: SystemProps) {
  const fullTitle = title ? `${title} | Publit` : "Publit";

  return (
    <>
      <Head>
        <meta name="theme-color" content="white" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://publit.app/" />
        <meta property="og:image" content="https://publit.app/preview.png" />

        {content && (
          <>
            <meta name="description" key="description" content={content} />
            <meta property="og:description" content={content} />
          </>
        )}
        <title>{fullTitle}</title>
        <meta property="og:title" content={fullTitle} />
      </Head>
      {/* Facebook Pixel Code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '2782978765319532');
            fbq('track', 'PageView');
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{display: "none"}}
          src="https://www.facebook.com/tr?id=2782978765319532&ev=PageView&noscript=1"
        />
      </noscript>
      {/* End Facebook Pixel Code */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
html {
  --color-background-raw: ${props => props.theme.colors.background};
  --color-background: rgb(${props => props.theme.colors.background});
  --color-primary-raw: ${props => props.theme.colors.primary};
  --color-primary: rgb(${props => props.theme.colors.primary});
  --color-secondary-raw: ${props => props.theme.colors.secondary};
  --color-secondary: rgb(${props => props.theme.colors.secondary});
  --color-tint-raw: ${props => props.theme.colors.tint};
  --color-tint: rgb(${props => props.theme.colors.tint});
  --color-error-raw: ${props => props.theme.colors.error};
  --color-error: rgb(${props => props.theme.colors.error});
  --color-accent-raw: ${props => props.theme.colors.accent};
  --color-accent: rgb(${props => props.theme.colors.accent});
  --color-text-primary-raw: ${props => props.theme.colors.textPrimary};
  --color-text-primary: rgb(${props => props.theme.colors.textPrimary});
  --color-text-secondary-raw: ${props => props.theme.colors.textSecondary};
  --color-text-secondary: rgb(${props => props.theme.colors.textSecondary});
  --color-text-tint-raw: ${props => props.theme.colors.textTint};
  --color-text-tint: rgb(${props => props.theme.colors.textTint});
  --color-text-accent-raw: ${props => props.theme.colors.textAccent};
  --color-text-accent: rgb(${props => props.theme.colors.textAccent});

  --color-pink-raw: ${props => props.theme.colors.pink};
  --color-pink: rgb(${props => props.theme.colors.pink});
  --color-light-blue-raw: ${props => props.theme.colors.lightBlue};
  --color-light-blue: rgb(${props => props.theme.colors.lightBlue});
  --color-blue-raw: ${props => props.theme.colors.blue};
  --color-blue: rgb(${props => props.theme.colors.blue});
  --color-navy-raw: ${props => props.theme.colors.navy};
  --color-navy: rgb(${props => props.theme.colors.navy});
  --color-light-green-raw: ${props => props.theme.colors.lightGreen};
  --color-light-green: rgb(${props => props.theme.colors.lightGreen});
  --color-green-raw: ${props => props.theme.colors.green};
  --color-green: rgb(${props => props.theme.colors.green});
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: var(--color-text-primary);
  font-size: 18px;
  line-height: 25px;
  background: white;
}
`;
