import {css} from "styled-components";
import * as text from "./text";
import * as shadows from "./shadows";

const contentWidth = css`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  // 1500 = 1400 + 2 * 50
  @media (max-width: calc(1500px)) {
    margin: 0 50px;
    width: auto;
  }
`;

const theme = {
  colors: {
    // these are raw rgb() values
    // storing them like this allows us to use as color
    // but also transform them
    background: "249, 252, 255",
    primary: "5, 226, 0",
    secondary: "128, 239, 17",
    tint: "250, 255, 0",
    error: "255, 15, 0",
    accent: "0, 194, 255",
    textPrimary: "68, 68, 68",
    textSecondary: "0, 0, 0",
    textTint: "4, 212, 0",
    textAccent: "0, 174, 228",

    // v2 colors
    pink: "255, 58, 224",
    lightBlue: "31, 172, 255",
    blue: "0, 127, 245",
    navy: "19, 51, 100",
    lightGreen: "0, 255, 25",
    green: "0, 212, 21",
  },
  z: {
    heroShadows: 0,

    // these are all part of the container header
    dashboardHeader: 1,
    sidebar: 2,
    subheader: 3,
    navbar: 4,

    tooltip: 10,

    indicator: 20,

    // this is the marketing header
    headerNavigation: 30,

    toast: 35,

    popup: 40,

    modal: 50,
    modalBackground: 51,
    modalContent: 52,
  },
  css: {
    contentWidth,
  },
  widths: {
    medium: "1000px",
    small: "500px",
  },
  text,
  shadows,
};

export default theme;
