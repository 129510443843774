const System = () => (
  <svg
    width="50"
    height="30"
    viewBox="0 0 50 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="bottom"
      opacity="0.6"
      d="M23.0861 12.0304C24.1194 11.64 25.797 11.6336 26.8332 12.0162L48.1238 19.8776C49.16 20.2602 49.1624 20.8868 48.1291 21.2772L26.9139 29.2931C25.8806 29.6835 24.203 29.6898 23.1668 29.3072L1.87619 21.4459C0.840002 21.0633 0.837636 20.4366 1.87091 20.0462L23.0861 12.0304Z"
      fill="black"
    />
    <path
      id="middle"
      opacity="0.8"
      d="M23.0861 6.36876C24.1194 5.97835 25.797 5.97202 26.8332 6.35463L48.1238 14.2159C49.16 14.5985 49.1624 15.2252 48.1291 15.6156L26.9139 23.6314C25.8806 24.0218 24.203 24.0282 23.1668 23.6456L1.87619 15.7843C0.840002 15.4017 0.837636 14.775 1.87091 14.3846L23.0861 6.36876Z"
      fill="black"
    />
    <path
      id="top"
      d="M23.0861 0.706895C24.1194 0.316488 25.797 0.31016 26.8332 0.692761L48.1238 8.55406C49.16 8.93667 49.1624 9.56331 48.1291 9.95372L26.9139 17.9696C25.8806 18.36 24.203 18.3663 23.1668 17.9837L1.87619 10.1224C0.840002 9.73979 0.837636 9.11315 1.87091 8.72274L23.0861 0.706895Z"
      fill="black"
    />
    <animatetransform
      xlinkHref="#top"
      attributeName="transform"
      attributeType="XML"
      type="translate"
      values="0 4; 0 0; 0 4"
      keyTimes="0; 0.5; 1"
      dur="3s"
      calcMode="spline"
      keySplines="0.4 0 .52 .99; 0.4 0 .52 .99"
      repeatCount="indefinite"
    />

    <animatetransform
      xlinkHref="#bottom"
      attributeName="transform"
      attributeType="XML"
      type="translate"
      values="0 -4; 0 0; 0 -4"
      keyTimes="0; 0.5; 1"
      dur="3s"
      calcMode="spline"
      keySplines="0.4 0 .52 .99; 0.4 0 .52 .99"
      repeatCount="indefinite"
    />
  </svg>
);

export default System;
