import Link from "next/link";
import s from "styled-components";

const BrandComponent = ({white = false}) => (
  <Link href="/" passHref legacyBehavior>
    <BrandLink aria-label="Publit">
      <svg
        width="117"
        height="24"
        viewBox="0 0 117 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106 0H98.8V3.2H106V24H109.2V3.2H116.4V0H109.2H106Z"
          fill={white ? "white" : "url(#paint0_linear_3_2925)"}
        />
        <path
          d="M89.2 0H92.4V24H89.2V0Z"
          fill={white ? "white" : "url(#paint1_linear_3_2925)"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70 0H66.8L66.8 20.8L66.8 24H70H82.8V20.8H70V0Z"
          fill={white ? "white" : "url(#paint2_linear_3_2925)"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.8 0H44.8H48H52.4H52.8V0.012297C56.1483 0.218777 58.8 2.99972 58.8 6.4C58.8 8.07758 58.1546 9.60441 57.0984 10.7457C59.0849 12.0274 60.4 14.2601 60.4 16.8C60.4 20.6422 57.3904 23.7815 53.6 23.9891V24H53.2H48H44.8L44.8 20.8L44.8 12.8V9.6V0ZM48 9.6H52.4C54.1673 9.6 55.6 8.16731 55.6 6.4C55.6 4.63269 54.1673 3.2 52.4 3.2H48V9.6ZM48 12.8V20.8H53.2H53.4V20.7951C55.5162 20.6909 57.2 18.9421 57.2 16.8C57.2 14.6579 55.5162 12.9091 53.4 12.8049V12.8H53.2L52.8 12.8H52.4L48 12.8Z"
          fill={white ? "white" : "url(#paint3_linear_3_2925)"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4 0H23.6V15.2H23.6034C23.7088 18.3106 26.2637 20.8 29.4 20.8C32.5363 20.8 35.0912 18.3106 35.1966 15.2H35.2V0H38.4V15.2H38.3978C38.2915 20.0782 34.3037 24 29.4 24C24.4963 24 20.5085 20.0782 20.4022 15.2H20.4V0Z"
          fill={white ? "white" : "url(#paint4_linear_3_2925)"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.2 0H0V3.2V9.6V12.8V24H3.2V12.8H8V12.7877C8.13229 12.7959 8.26566 12.8 8.4 12.8C11.9346 12.8 14.8 9.93462 14.8 6.4C14.8 2.86538 11.9346 0 8.4 0C8.26566 0 8.13229 0.00413912 8 0.0122968V0H3.2ZM3.2 9.6H8V9.57524C8.13104 9.59158 8.26454 9.6 8.4 9.6C10.1673 9.6 11.6 8.16731 11.6 6.4C11.6 4.63269 10.1673 3.2 8.4 3.2C8.26454 3.2 8.13104 3.20842 8 3.22476V3.2H3.2V9.6Z"
          fill={white ? "white" : "url(#paint5_linear_3_2925)"}
        />
        <defs>
          <linearGradient
            id="paint0_linear_3_2925"
            x1="107.6"
            y1="0"
            x2="107.6"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1FACFF" />
            <stop offset="1" stopColor="#007FF5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3_2925"
            x1="90.8"
            y1="0"
            x2="90.8"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1FACFF" />
            <stop offset="1" stopColor="#007FF5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3_2925"
            x1="74.8"
            y1="0"
            x2="74.8"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1FACFF" />
            <stop offset="1" stopColor="#007FF5" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3_2925"
            x1="52.6"
            y1="0"
            x2="52.6"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1FACFF" />
            <stop offset="1" stopColor="#007FF5" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_3_2925"
            x1="29.4"
            y1="0"
            x2="29.4"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1FACFF" />
            <stop offset="1" stopColor="#007FF5" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_3_2925"
            x1="7.4"
            y1="0"
            x2="7.4"
            y2="24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1FACFF" />
            <stop offset="1" stopColor="#007FF5" />
          </linearGradient>
        </defs>
      </svg>
    </BrandLink>
  </Link>
);

export default BrandComponent;

const BrandLink = s.a`
display: flex;
align-items: center;
color: inherit;
text-decoration: none;
margin: 0;
padding: 0;
`;
