const Person = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 14.4444C16 16.899 10.4546 18 8 18C5.5454 18 0 16.899 0 14.4444C0 11.9898 3.76762 10 8 10C12.2324 10 16 11.9898 16 14.4444Z"
      fill="#DDDFE5"
    />
    <circle cx="8" cy="4" r="4" fill="#DDDFE5" />
  </svg>
);

export default Person;
