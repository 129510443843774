const Rocket = () => (
  <svg
    width="22"
    height="44"
    viewBox="0 0 22 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="r-body"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0C11.8168 0 16.2739 6.30922 17.5642 14.8015L20.159 17.4652C21.3378 18.6751 22 20.3161 22 22.0272V28.2258C22 28.493 21.7889 28.7097 21.5286 28.7097C21.4512 28.7097 21.375 28.6901 21.3067 28.6528L17.3828 26.5048C17.2331 27.4804 17.0548 28.417 16.8518 29.3071C14.9041 29.769 12.9563 30 11.0085 30C9.05906 30 7.10554 29.7686 5.14796 29.3059C4.94502 28.4162 4.76682 27.48 4.61723 26.5049L0.693277 28.6528C0.463545 28.7785 0.177986 28.6893 0.0554622 28.4535C0.0190477 28.3834 0 28.3052 0 28.2258V22.0272C0 20.3161 0.662243 18.6751 1.84104 17.4652L4.43579 14.8017C5.72606 6.3093 10.1832 0 11 0ZM10.9027 1.38509L10.8878 1.39816L10.8259 1.46067C10.496 1.79979 10.1671 2.23078 9.83913 2.75364C9.48648 3.31582 9.21047 3.85518 9.0111 4.37171C8.98203 4.44703 9.01203 4.53169 9.07897 4.57102L9.09507 4.57927L9.1903 4.62029C9.79345 4.87343 10.3966 5 10.9998 5C11.6366 5 12.2736 4.85888 12.9108 4.57665C12.9808 4.54564 13.0182 4.46819 13.0019 4.39356L12.9967 4.375L12.9681 4.29361C12.8005 3.82951 12.5449 3.31619 12.2011 2.75364C11.808 2.1997 11.4439 1.74873 11.1088 1.40072C11.0529 1.34268 10.9646 1.33724 10.9027 1.38509Z"
      fill="white"
    />
    <rect id="r-r-b" x="15" y="39" width="2" height="4" rx="1" fill="white" />
    <rect id="r-r-m" x="15" y="36" width="2" height="2" rx="1" fill="white" />
    <rect id="r-r-t" x="15" y="31" width="2" height="4" rx="1" fill="white" />
    <rect id="r-rm-b" x="12" y="38" width="2" height="6" rx="1" fill="white" />
    <rect id="r-rm-t" x="12" y="31" width="2" height="6" rx="1" fill="white" />
    <rect id="r-lm-b" x="9" y="40" width="2" height="4" rx="1" fill="white" />
    <rect id="r-lm-t" x="9" y="31" width="2" height="8" rx="1" fill="white" />
    <rect id="r-l-b" x="6" y="41" width="2" height="2" rx="1" fill="white" />
    <rect id="r-l-m" x="6" y="36" width="2" height="4" rx="1" fill="white" />
    <rect id="r-l-t" x="6" y="31" width="2" height="4" rx="1" fill="white" />

    <animatetransform
      xlinkHref="#r-body"
      attributeName="transform"
      attributeType="XML"
      type="translate"
      values="0 0; 0 2; 0 0"
      keyTimes="0; 0.1; 1"
      dur="1s"
      calcMode="spline"
      keySplines=".38,.07,.19,.94; .38,.07,.19,.94"
      begin="r-l-b-o-a.begin + 0s"
      id="star-sr-r"
    />

    <animate
      xlinkHref="#r-l-b"
      attributeType="CSS"
      attributeName="opacity"
      values="1; 0; 1"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="r-l-b-o-a"
      begin="0s; r-l-b-o-a.end + 0.75s"
    />
    <animate
      xlinkHref="#r-l-m"
      attributeType="CSS"
      attributeName="opacity"
      values="1; 0; 1"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="r-l-m-o-a"
      begin="0.375s; r-l-m-o-a.end + 0.75s"
    />
    <animate
      xlinkHref="#r-lm-t"
      attributeType="CSS"
      attributeName="opacity"
      values="1; 0; 1"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="r-lm-t-o-a"
      begin="0.65s; r-lm-t-o-a.end + 0.75s"
    />
    <animate
      xlinkHref="#r-lm-b"
      attributeType="CSS"
      attributeName="opacity"
      values="1; 0; 1"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="r-lm-b-o-a"
      begin="0.2s; r-lm-b-o-a.end + 0.75s"
    />
    <animate
      xlinkHref="#r-rm-b"
      attributeType="CSS"
      attributeName="opacity"
      values="1; 0; 1"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="r-rm-b-o-a"
      begin="0.1s; r-rm-b-o-a.end + 0.75s"
    />
    <animate
      xlinkHref="#r-r-t"
      attributeType="CSS"
      attributeName="opacity"
      values="1; 0; 1"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="r-r-t-o-a"
      begin="0.45s; r-r-t-o-a.end + 0.75s"
    />
    <animate
      xlinkHref="#r-r-b"
      attributeType="CSS"
      attributeName="opacity"
      values="1; 0; 1"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="r-r-b-o-a"
      begin="0.2s; r-r-b-o-a.end + 0.75s"
    />
  </svg>
);

export default Rocket;
