const Money = () => (
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22"
      cy="18"
      r="16"
      fill="#F9FCFF"
      stroke="#05E200"
      strokeWidth="4"
    />
    <circle
      cx="18"
      cy="18"
      r="16"
      fill="#F9FCFF"
      stroke="url(#paint0_linear_60_731)"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0014 24.94V27H19.0014V24.9631C22.3686 24.7148 24.0001 22.9477 24.0001 20.6595C24.0001 17.8101 21.3267 17.03 19.0014 16.5031V13.6699C20.0665 13.8659 21.1055 14.3095 21.9454 15.03L23.6722 12.924C22.4452 11.8734 20.8703 11.2476 19.0014 11.0839V9H17.0014V11.095C14.1591 11.4167 12.459 13.1282 12.459 15.1515C12.459 17.8325 14.7944 18.643 17.0014 19.161V22.3591C15.6044 22.0928 14.4749 21.4243 13.6831 20.6595L12 22.8465C13.1596 23.9381 14.7994 24.7332 17.0014 24.94ZM19.0014 22.4384C20.2446 22.2704 20.8525 21.6418 20.8525 20.9835C20.8525 20.258 20.0789 19.904 19.0014 19.6205V22.4384ZM17.0014 16.0236V13.6105C16.1129 13.772 15.6284 14.2544 15.6284 14.9085C15.6284 15.4598 16.1806 15.7746 17.0014 16.0236Z"
      fill="url(#paint1_linear_60_731)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_60_731"
        x1="18"
        y1="0"
        x2="18"
        y2="36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C2FF" />
        <stop offset="1" stopColor="#016AC9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_60_731"
        x1="18"
        y1="9"
        x2="18"
        y2="27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01ADF2" />
        <stop offset="1" stopColor="#0181D7" />
      </linearGradient>
    </defs>
  </svg>
);

export default Money;
