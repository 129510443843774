const Stars = () => (
  <svg
    width="37"
    height="46"
    viewBox="0 0 37 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
  >
    <path
      id="big"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 39.9276L4.58359 45.354L7.2 36.2608L0 30.5212L9.03344 30.3276L12 21.354L14.9666 30.3276L24 30.5212L16.8 36.2608L19.4164 45.354L12 39.9276Z"
      fill="white"
    />
    <path
      id="small-right"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 21.4449L24.0557 25.0625L25.8 19.0003L21 15.174L27.0223 15.0449L29 9.0625L30.9777 15.0449L37 15.174L32.2 19.0003L33.9443 25.0625L29 21.4449Z"
      fill="white"
    />
    <path
      id="small-top"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 12.3824L6.05572 16L7.8 9.93783L3 6.11146L9.02229 5.98241L11 0L12.9777 5.98241L19 6.11146L14.2 9.93783L15.9443 16L11 12.3824Z"
      fill="white"
    />

    <animatetransform
      xlinkHref="#small-top"
      attributeName="transform"
      attributeType="XML"
      type="translate"
      values="0 0; -4 0; 2 0; -4 0; 4 0; -4 0; 4 0; -4 0; 2 0; -1 0; 0 0;"
      keyTimes="0; 0.1; 0.2; 0.3; 0.4; 0.5; 0.6; 0.7; 0.8; 0.9; 1"
      dur="2s"
      calcMode="spline"
      keySplines=".36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97"
      begin="0s; star-b-t.end + 1s"
      id="star-st-t"
    />
    <animatetransform
      xlinkHref="#small-top"
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      additive="sum"
      values="0 11 8; 20 11 8; -10 11 8; 10 11 8; -30 11 8; 20 11 8; -20 11 8; 30 11 8; -10 11 8; 10 11 8; 0 11 8;"
      keyTimes="0; 0.1; 0.2; 0.3; 0.4; 0.5; 0.6; 0.7; 0.8; 0.9; 1"
      dur="2s"
      calcMode="spline"
      keySplines=".36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97"
      begin="0s; star-b-r.end + 1s"
      id="star-st-r"
    />

    <animatetransform
      xlinkHref="#small-right"
      attributeName="transform"
      attributeType="XML"
      type="translate"
      values="0 0; -4 0; 2 0; -4 0; 4 0; -4 0; 4 0; -4 0; 2 0; -1 0; 0 0;"
      keyTimes="0; 0.1; 0.2; 0.3; 0.4; 0.5; 0.6; 0.7; 0.8; 0.9; 1"
      dur="2s"
      calcMode="spline"
      keySplines=".36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97"
      begin="star-st-t.end + 1s"
      id="star-sr-t"
    />
    <animatetransform
      xlinkHref="#small-right"
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      additive="sum"
      values="0 29 17; 20 29 17; -10 29 17; 10 29 17; -30 29 17; 20 29 17; -20 29 17; 30 29 17; -10 29 17; 10 29 17; 0 29 17;"
      keyTimes="0; 0.1; 0.2; 0.3; 0.4; 0.5; 0.6; 0.7; 0.8; 0.9; 1"
      dur="2s"
      calcMode="spline"
      keySplines=".36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97"
      begin="star-st-r.end + 1s"
      id="star-sr-r"
    />

    <animatetransform
      xlinkHref="#big"
      attributeName="transform"
      attributeType="XML"
      type="translate"
      values="0 0; -4 0; 2 0; -4 0; 4 0; -4 0; 4 0; -4 0; 2 0; -1 0; 0 0;"
      keyTimes="0; 0.1; 0.2; 0.3; 0.4; 0.5; 0.6; 0.7; 0.8; 0.9; 1"
      dur="2s"
      calcMode="spline"
      keySplines=".36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97"
      begin="star-sr-t.end + 1s"
      id="star-b-t"
    />
    <animatetransform
      xlinkHref="#big"
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      additive="sum"
      values="0 12 33; 20 12 33; -10 12 33; 10 12 33; -30 12 33; 20 12 33; -20 12 33; 30 12 33; -10 12 33; 10 12 33; 0 12 33;"
      keyTimes="0; 0.1; 0.2; 0.3; 0.4; 0.5; 0.6; 0.7; 0.8; 0.9; 1"
      dur="2s"
      calcMode="spline"
      keySplines=".36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97; .36 .07 .19 .97"
      begin="star-sr-r.end + 1s"
      id="star-b-r"
    />
  </svg>
);

export default Stars;
