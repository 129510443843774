const Heart = () => (
  <svg
    width="44"
    height="36"
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.5685 3.37814C45.1438 7.88232 45.1438 15.185 40.5685 19.6892L40.5146 19.742L23.9999 36L7.47446 19.7313C7.46009 19.7173 7.44576 19.7033 7.43146 19.6892C2.85618 15.185 2.85618 7.88232 7.43146 3.37814C12.0067 -1.12599 19.4245 -1.12605 23.9998 3.37797L23.9999 3.37787L24 3.37814L24.0001 3.37803C28.5754 -1.12605 35.9933 -1.12601 40.5685 3.37814Z"
      fill="#05E200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5685 3.37814C41.1438 7.88232 41.1438 15.185 36.5685 19.6892L36.5146 19.742L19.9999 36L3.47446 19.7313C3.46009 19.7173 3.44576 19.7033 3.43146 19.6892C-1.14382 15.185 -1.14382 7.88232 3.43146 3.37814C8.00667 -1.12599 15.4245 -1.12605 19.9998 3.37797L19.9999 3.37787L20 3.37814L20.0001 3.37803C24.5754 -1.12605 31.9933 -1.12601 36.5685 3.37814Z"
      fill="url(#paint0_linear_227_225)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_227_225"
        x1="20"
        y1="0"
        x2="20"
        y2="36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C2FF" />
        <stop offset="1" stopColor="#016AC9" />
      </linearGradient>
    </defs>
  </svg>
);

export default Heart;
