const Star = () => (
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 27.8604L10.8754 36L14.8 22.3601L4 13.7508L17.5502 13.4604L22 0L26.4498 13.4604L40 13.7508L29.2 22.3601L33.1246 36L22 27.8604Z"
      fill="#05E200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 27.8604L6.87538 36L10.8 22.3601L0 13.7508L13.5502 13.4604L18 0L22.4498 13.4604L36 13.7508L25.2 22.3601L29.1246 36L18 27.8604Z"
      fill="url(#paint0_linear_60_546)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_60_546"
        x1="18"
        y1="0"
        x2="18"
        y2="36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C2FF" />
        <stop offset="1" stopColor="#016AC9" />
      </linearGradient>
    </defs>
  </svg>
);

export default Star;
