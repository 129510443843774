const LogoColored = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V28C0 29.1046 0.89543 30 2 30H28C29.1046 30 30 29.1046 30 28V2C30 0.895431 29.1046 0 28 0H2ZM6 5C5.44772 5 5 5.44772 5 6V24C5 24.5523 5.44772 25 6 25H24C24.5523 25 25 24.5523 25 24V6C25 5.44772 24.5523 5 24 5H6Z"
      fill="var(--color-tint)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8C8.89543 8 8 8.89543 8 10V36C8 37.1046 8.89543 38 10 38H36C37.1046 38 38 37.1046 38 36V10C38 8.89543 37.1046 8 36 8H10ZM14 13C13.4477 13 13 13.4477 13 14V32C13 32.5523 13.4477 33 14 33H32C32.5523 33 33 32.5523 33 32V14C33 13.4477 32.5523 13 32 13H14Z"
      fill="var(--color-primary)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V32C4 33.1046 4.89543 34 6 34H32C33.1046 34 34 33.1046 34 32V6C34 4.89543 33.1046 4 32 4H6ZM10 9C9.44772 9 9 9.44772 9 10V28C9 28.5523 9.44772 29 10 29H28C28.5523 29 29 28.5523 29 28V10C29 9.44772 28.5523 9 28 9H10Z"
      fill="black"
    />
  </svg>
);

export default LogoColored;
