const Plus = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="8" width="20" height="4" fill="var(--color-accent)" />
    <rect x="8" width="4" height="20" fill="var(--color-accent)" />
  </svg>
);

export default Plus;
